// targets the stepper's label text
.font-color + div {
  span {
    color: #000 !important;
  }
}

.font-color[disabled] + div {
  span {
    color: #bdbdbd !important;
  }
}

.form-input-label {
  color: #000000;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 700;
}

body {
  font-family: "open sans", "helvetica neue", "Helvetica", "Arial", "sans-serif" !important;
}

h4 {
  font-family: "open sans", "helvetica neue", "Helvetica", "Arial", "sans-serif" !important;
  font-size: 32px;
  line-height: 32px;
  font-weight: 300 !important;
  color: #000;
}

.btn {
  line-height: 26px;
  font-size: 16px;
  font-family: "open sans", "helvetica neue", "Helvetica", "Arial", "sans-serif" !important;
  text-transform: uppercase;
  font-weight: 600;
  border-color: transparent;
  color: #fff;
  border-radius: 3px;
  padding: 10px 20px;

  &:disabled {
    background-color: #c7c7c7 !important;
  }
  .actv {
    &:hover {
      cursor: none;
    }
  }
}

.form-control {

  &:not(textarea):not(div) {
    height: 2.65rem !important;
  }

  // &:not(textarea):is(div) {


  // }

  &:not(.is-invalid):active {
    color: #5a6169;
    border-color: #ccc !important;
  }
  &:not(.is-invalid):focus {
    border-color: #ccc !important;
  }
  border-radius: 5px;
  // border-width: 2px;
  background-color: #fff;
  border: 2px solid #ccc;
  box-shadow: none;
  vertical-align: middle;
  &.custom-select {
    border: 2px solid #ccc !important;
   }
}

.percent-dropdown {
    font-size: 16px;
    font-weight: 500;
    // text-align: center !important;
    // display: inline;
    // vertical-align: middle;
    padding: 0;
    padding-left: .75rem;;
    // line-height: 24px;
  option {
    font-size: 16px;
    font-weight: 500;
    // line-height: 24px;

  }
}

.stepper-step {
  &:hover {
    cursor: inherit;
  }
}

.background-image {
  background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 80%),
  url("../images/backgrounds/background-4.jpg");

  // linear-gradient(180deg,#ffd100 0%,rgba(235,235,235,0.9) 100%)!important;
}

.esign-error {
  position: fixed;
  z-index: 100;
}

.sticky-header {
  position: auto;
  border-color: #dee2e6;
  border-spacing: 0;
  border-collapse: separate;
  th {
    position: sticky !important;
    top: 0px;
    // background-color: #eee;
    z-index: 30;
    // border-collapse: separate !important;
    background-color: white;
    opacity: 1;
    border-top: 1px solid !important;
    border-bottom: 1px solid !important;
    border-right: 0px solid !important;
    border-color: #dee2e6 !important;
  }

  td {
    border-bottom: 0px solid !important;
    border-right: 0px solid !important;
    border-color: #dee2e6 !important;
  }

  th:first-child,
  td:first-child {
    /* Apply a left border on the first <td> or <th> in a row */
    border-left: 1px solid !important;
    border-color: #dee2e6 !important;
  }
}

.modal-body {
  h5 {
    color: #212529;
  }
}

.custom-maxwidth {
  width: 65%;
}

.auth-form__meta a {
  color: #000000;
}


