// Buttons Adjustments

//
// White and black button adjustments specific to Shards Dashboards.
// If you want to use the default Shards buttons just comment the
// styles below.
//
.btn {
  font-size: .95rem;
}

.btn-white,
.btn-black {
  // Set the defaults to use the accent color.
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active {
    box-shadow: none !important;
    background-color: $accent-color;
    border-color: $accent-color;
    color: $white;

    + .btn {
      border-left: 1px solid $accent-color;
    }
  }

  &:hover {
    background: $white;
    border-color: $white;
    box-shadow: 0px 0.125rem 0.625rem rgba($reagent-gray, .2),
                0 0.0625rem 0.125rem rgba($reagent-gray, .3);
  }
}

.btn.btn-secondary {
  background: #0133b6;
  border: #0133b6;
  &:not(.actv):hover {
    background: rgba(242, 107, 14, 1);
    border: rgba(242, 107, 14, 1);
    box-shadow: none !important;
  }
  &.actv {
    &:hover {
      box-shadow: none !important;
      cursor: inherit;
    }
  }
  box-shadow: none;
  &:not([disabled]):not(.disabled):active {
    background: rgba(242, 107, 14, 1);
    border: rgba(242, 107, 14, 1);
    box-shadow: none;
  }
}

//CUSTOM GSS STYLES FOR BUTTONS
.btn.btn-primary {
  background: rgba(242, 107, 14, 1);
  border: rgba(242, 107, 14, 1);
  &:not(.actv):hover {
    background: #0133b6;
    border: #0133b6;
    box-shadow: none !important;
  }
  .actv {
      &:hover {
      box-shadow: none !important;
      cursor: inherit;
    }
  }
  &:not(.actv):focus {
    box-shadow: none;
  }
  &:not([disabled]):not(.disabled):active {
    background: #0133b6 !important;
    border: #0133b6 !important;
    box-shadow: none;
  }
}

// White button accent color modifiers.
// Selector specificity to override existing active state.
.btn.btn-white {
  border: 1px solid $border-color;
  color: $fiord-blue;

  @mixin btn-active-modifier($color, $value) {
    &:not([disabled]):not(.disabled).active-#{$color} {
      &.active,
      &:active {
        background-color: $value;
        border-color: $value;
        color: color-yiq($value);

        & + .btn {
          border-left: 1px solid red;
        }
      }
    }
  }

  // Existent theme colors
  @each $color, $value in $theme-colors {
    @include btn-active-modifier($color, $value);
  }

  // New colors
  @each $color, $value in $new-colors {
    @include btn-active-modifier($color, $value);
  }
}

// Button variations for the new colors.
@each $color, $value in $new-colors {
  .btn-#{$color} {
    @include button-variant($value);
  }
}

// Button variation for the accent color.
.btn-accent {
  @include button-variant($accent-color);
}

// Outline button variations for the new colors.
@each $color, $value in $new-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

// Outline button variation for the accent color.
.btn-outline-accent {
  @include button-outline-variant($accent-color);
}
