// Main footer

.main-footer {
  height: $main-footer-height;
  background-color: #000;
  font-weight: 300;
  font-family: sans-serif;
  line-height: 15px;
  font-size: 12px;
  // padding: 7px 0px 8px 17px;
  // font-family: 'open sans,helvetica neue,Helvetica,Arial,sans-serif';
  span {
    font-size: 1.1em;
    font-weight: 100;
  }

  .copyright {
    color: $main-footer-copyright-color;
    & > a {
      font-weight: 700;
      color: #999;
    }
  }

  @include media-breakpoint-down(sm) {
    display: block !important;
    height: auto;

    .nav {
      width: 100%;
      display: block;
      border-bottom: 1px solid $border-color;
      padding-bottom: $spacer / 2;
    }

    .copyright {
      display: inline-block;
      width: 100%;
      padding: 1rem;
      text-align: center;
      color: bg-white border-top;
      & > a {
        font-weight: 700;
        color: #999;
      }
    }
  }
}
